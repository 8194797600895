{
  "404": {
    "message": "The requested page could not be found. <br> May we help you with the search? Just write us a message or call us.",
    "title": "Page not found"
  },
  "account": {
    "additional_address_info_label": "Additional address line",
    "change_tmp_pwd_cta": "Change password",
    "city_label": "City",
    "countries": {
      "AT": "Austria",
      "BE": "Belgium",
      "CH": "Switzerland",
      "DE": "Germany",
      "DK": "Denmark",
      "ES": "Spain",
      "LU": "Luxembourg",
      "NL": "Netherlands",
      "PL": "Poland",
      "PT": "Portugal"
    },
    "country_label": "Country",
    "email_label": "Email",
    "firstname_label": "First name",
    "forgot_pwd_cta_no": "Cancel",
    "forgot_pwd_cta_yes": "Request",
    "forgot_pwd_title": "We are resetting your password",
    "forgotten_pwd": "Forgot password?",
    "house_number_label": "Nr.",
    "lastname_label": "Last name",
    "login_expired": "Your login is invalid, please login again",
    "password_label": "Password",
    "phone": "Phone number",
    "register_cta": "Register",
    "save": "Save",
    "save-success": {
      "button": "OK",
      "text": "We have successfully saved your address. Future invoices will now be sent to this address.",
      "title": "Address has been saved"
    },
    "street_label": "Street",
    "tax": {
      "change_text": "If you would like to change your tax identification number, please contact our",
      "error": "This tax identification number is not valid in Portugal",
      "label": "Tax ID",
      "success": "VAT identification number has been saved",
      "support": "Support",
      "title": "For ryd pay we need your tax identification number"
    },
    "update_personal_information_cta": "Update",
    "validationErrorAGB": "You must accept the terms and conditions",
    "zip_code_label": "Zip code"
  },
  "car": {
    "data": {
      "already_preferred": "This car is already set as preferred",
      "brand": "Brand",
      "brand_placeholder": "Select car brand",
      "button": {
        "action1": "Set as active",
        "action2": "Save",
        "action3": "Delete"
      },
      "capacity": "Fuel tank capacity (litre)",
      "capacity_placeholder": "Enter tank volume",
      "edit": "Edit vehicle",
      "fuel": "Fuel type",
      "fuel_placeholder": "Select car fuel type",
      "inspection": "Next general inspection",
      "inspection_placeholder": "Enter next car inspection date",
      "license": "License plate",
      "license_placeholder": "Enter car license plate",
      "mileage": "Mileage",
      "mileage_placeholder": "Enter car mileage",
      "model": "Model",
      "model_placeholder": "Select car model",
      "more": "More vehicle information ",
      "name": "Name",
      "name_placeholder": "Enter car name",
      "vin": "Vehicle ID (VIN)",
      "vin_placeholder": "Enter car VIN",
      "year": "Build year",
      "year_placeholder": "Select car build year"
    },
    "error": {
      "create": "There was an error creating your car. Please try again later.",
      "delete": "There was an error deleting your car. Please try again later.",
      "update": "There was an error updating your car. Please try again later."
    },
    "modal": {
      "choice": {
        "button": {
          "action1": "Cancel",
          "action2": "Confirm"
        }
      },
      "confirmation": {
        "button": {
          "action1": "No",
          "action2": "Yes"
        },
        "text": "Your car and all its details will be removed.",
        "title": "Are you sure?"
      }
    },
    "page": {
      "title": "Edit car info"
    }
  },
  "cars": {
    "button": {
      "action1": "Add a new car"
    },
    "list": {
      "license_plate": "License plate",
      "status": "Active"
    },
    "page": {
      "title": "Cars"
    },
    "subtitle": "Access and edit your car data",
    "title": "Manage your cars"
  },
  "errors": {
    "active_payments_supported_by_poi": "We coulnd't get your payment informations, please wait, or try again",
    "api_station": {
      "text": "Unfortunately, the pump does not give us any feedback. Please try again or pay at the gas station.",
      "title": "Process failed"
    },
    "button_ok": "OK",
    "dashboard_title": "Something went wrong",
    "email_not_valid": "Enter a valid e-mail address",
    "format_field_error": "The {field} field format is invalid",
    "fueling_postpay_order": {
      "text": "Unfortunately, there was a problem with your order. Please pay at the gas station.",
      "title": "Order is expired"
    },
    "fueling_postpay": {
      "text": "Unfortunately, the pump does not give us any feedback. Please try again or pay at the gas station.",
      "title": "Process failed"
    },
    "fueling_prepay": {
      "text": "The process was canceled automatically. <br>If refueling has already started, the final amount will be invoiced after the nozzle has been hooked in. We will send you the invoice by email soon.",
      "title": "System does not respond"
    },
    "generic": {
      "text": "There was an error. Please try again later!",
      "title": "Error"
    },
    "invalid_phone_number": "This phone number is invalid.",
    "NO_PUMPS": {
      "text": "No pumps have been found at this gas station",
      "title": "No Pumps"
    },
    "NO_STATION_FOUND_LAT_LON": {
      "text": "No gas stations have been found at this location",
      "title": "No Gas Station"
    },
    "nopsp": {
      "btn": "Close",
      "text": "Please contact the support:",
      "title": "Unfortunately there seems to be an issue with your account. "
    },
    "password_not_correct": "The password you entered is incorrect",
    "password_not_same": "The password you entered again does not match your password",
    "password_not_valid": "Your password must be between 8-40 characters long.",
    "payment_edit": {
      "text": "Unfortunately, your payment method could not be edited. Please try again.",
      "title": "Error"
    },
    "payment_register": {
      "text": "Unfortunately, your payment method could not be saved. Please try again.",
      "title": "Error"
    },
    "payment": {
      "text": "Unfortunately, something went wrong. Please try again or pay at the gas station.",
      "title": "Payment failed"
    },
    "poi": "We couldn't get the gas station information, please wait, or try again",
    "required_field_error": "The {field} field is required",
    "STATION_OFFLINE": {
      "text": "This gas station is currently offline",
      "title": "Gas Station Offline"
    },
    "tax_error": "This tax identification number is not valid in Portugal",
    "temp_pass": "Seems like you are using your temporary password. Please change it.",
    "transactions": "please refresh your transactions",
    "try_again_button": "Try again",
    "webauthn_unsupported": {
      "text": "Unfortunately the device does not support biometric data.",
      "title": "Biometric data is not supported"
    },
    "wrong_login_details": "Username and/or password are wrong. Maybe you didn't create an account."
  },
  "footer": {
    "menuItems": {
      "AGB": "TnCs",
      "dataprotection": "Data privacy",
      "imprint": "Imprint",
      "statutoryInfos": "Additional statutory info"
    }
  },
  "forms": {
    "field_not_required": "optional"
  },
  "fueling": {
    "add_PM": "Add a new payment method",
    "amountSelection": {
      "text": "If you want to fuel up less, that is no problem. We only charge you for the amount of fuel actually filled up.",
      "title": "Select amount"
    },
    "find_another_poi_on_map": "Find another station on the map",
    "finish": {
      "button_ok": "Close",
      "checkout_modal_title": "Choose your payment method",
      "table": {
        "amount": "Amount",
        "fuelName": "Fuel type",
        "pricePerUnit": "Unit price",
        "pump": "Fuel pump",
        "pumpUnit": "Pump",
        "timestamp": "Time stamp",
        "total": "Total",
        "unit": "Amount"
      },
      "text": "You can continue driving. The gas station will be informed in real time. Your invoice will be sent by email.",
      "title": "Payment successful"
    },
    "fueling": {
      "button_cancel": "Cancel",
      "cancel_text": "You can cancel the process by pulling the nozzle and putting it back in place. <br> We will then send you an invoice for the amount you have filled up. Even if you have not refueled, we will send you an invoice for the 0€ amount you have refueled.",
      "cancel_title": "You want to cancel?",
      "subtitle": "Hint",
      "text": "{maxAmount} is the limit, we will make sure that this is not exceeded for you. If you want to fill up less, simply hang up the nozzle back earlier. Of course, we will only charge you for the amount of fuel actually filled up.",
      "text_postpax": "Ready to refuel? <br> Start the refueling process and end it by hanging up the fuel nozzle. <br>Shortly afterwards you will see the amount to be paid.",
      "title": "Pump ready"
    },
    "fuelSelection": {
      "title": "Select type"
    },
    "please_add_pm": "Please add a payment method",
    "pumpSelection": {
      "show_more_pumps": "Show more",
      "title": "Select pump"
    },
    "summary": {
      "buttons": {
        "add": "Add",
        "cancel": "Cancel",
        "change": "Change",
        "edit": "Edit selection",
        "login": "Login now",
        "pay": "Pay {total} now",
        "register": "or Register now for more options",
        "start": "Confirm"
      },
      "table": {
        "amount": "Max. amount",
        "fuelName": "Fuel type",
        "pump": "Fuel pump"
      },
      "text": {
        "auth_required": {
          "text": "To complete your order and pay for the fuelling you need to login into your account or register a new one",
          "title": "Almost done"
        },
        "device_not_supported": {
          "text": "Sorry, but Device payments like Google Pay or Apple Pay are not supported by your device, to use any other payment options you can:",
          "title": "Device payments not supported"
        },
        "or": "or",
        "poi_not_supported": {
          "text": "Sorry, but Device payments like Google Pay or Apple Pay are not supported by this gas station, to use any other payment options you can:",
          "title": "Device payments not supported"
        },
        "profile": "go to Profile",
        "register": "Register now for more options",
        "registerOrLogin": "Register or Login now for more options",
        "tncs": "With your payment confirmation you accept the <a href=\"{terms_and_conditions}\" target=\"_blank\">terms and conditions</a>. We process your data according to our <a href=\"{data_protection}\" target=\"_blank\">data protection information</a>."
      }
    }
  },
  "hints": {
    "btn_cta": "Select pump",
    "poi_aral_info": {
      "text": "To use ryd pay at this station, select the gas pump and the desired maximum tank amount before removing the tap."
    },
    "poi_h2Legal": {
      "btn_cta": "Confirm training",
      "text": "We would like to point out the training obligation as a prerequisite for hydrogen refuelling. This obligation remains with the customer.<br>By continuing, you confirm that you have familiarised yourself with the proper handling of the hydrogen refuelling process and that you will adhere to all necessary safety precautions.<br>An example video can be found <a href=\"{videoTutorial}\" target=\"_blank\">here</a>."
    },
    "poi_info": {
      "text": "For your best safety and comfort, pay for the fuelling inside the vehicle."
    }
  },
  "history": {
    "brand_unknow": "Unknown",
    "details": {
      "acceleration": "Acceleration",
      "amount": "Tank amount",
      "braking": "Braking",
      "cost": {
        "add_cta": "Add",
        "category": {
          "one": "One",
          "other": "Others",
          "zero": "Zero",
          "care": "Care",
          "charging": "Charging",
          "insurance": "Insurance",
          "leasing": "Leasing",
          "parking": "Parking",
          "refuel": "Refuel",
          "tax": "Tax",
          "tires": "Tires & Car parts",
          "wash": "Car wash",
          "workshop": "Workshop & Inspection"
        },
        "delete": {
          "all": "All entries",
          "cancel": "Cancel",
          "only_this": "This entry",
          "remove": "Remove",
          "this_and_following": "This and upcoming entries",
          "title": "Remove recurring entry",
          "title_deleteOnce": "Are you sure you want to remove the entry?"
        },
        "error_mileage": "Your mileage is lower than before topping up",
        "error_required": "Entry mandatory",
        "filter_category_title": "Select a cost entry category",
        "filter_repeat_title": "How often should it repeat?",
        "label_category": "Category",
        "label_comment": "Comment",
        "label_cost": "Cost",
        "label_date_time": "Date and Time",
        "label_ev_level": "Car fully charged",
        "label_image_preview": "Image preview",
        "label_mileage": "Mileage",
        "label_quantity": "Quantity",
        "label_repeat": "Recurring",
        "label_tank_level": "Tank completely fueled up",
        "label_title": "Title",
        "remove_cta": "Remove",
        "repeat": {
          "monthly": "Monthly",
          "never": "Never",
          "quarterly": "Quarterly",
          "weekly": "Weekly",
          "yearly": "Yearly"
        },
        "save_cta": "Save changes"
      },
      "events": "Events",
      "fuel_type": "Fuel type",
      "mileage": "Mileage",
      "noted_event": "event(s)",
      "order_id": "Payment number",
      "payment": {
        "ev": {
          "charging": "Energy",
          "from": "from",
          "mileage": "Mileage",
          "one_time_fee": "One time fee",
          "order": "OrderID",
          "parking": "Parking",
          "plug_power": "Output",
          "plug_type": "Plug type",
          "time": "Charging session",
          "time_tarif": "Idle / blocking fee",
          "to": "to",
          "total": "Total"
        }
      },
      "pump": "Fuel pump",
      "show_all": "Show all",
      "steering": "Steering",
      "tank": "Dispensing amount",
      "time_stamp": "Date",
      "title_cost": "Manual cost entry",
      "title_payments": "Payment details",
      "title_trip": "Trip details",
      "title_wash": "Car Wash",
      "total": "Grand total",
      "trip_details": "Trip details",
      "unit_price": "Unit price"
    },
    "empty": "We do not have any transactions for your account. In the future, you will find your refuelings, trips and other recorded events here.",
    "error_close": "Close",
    "error_imageSize": "Image too big, please load images below 6Mega",
    "error_invoice": "Unfortunately, something went wrong downloading your invoice",
    "error_subtitle": "There seems to be a problem with your cost entry data. Please check the entered data or try again a bit later.",
    "error_title": "Ops, something went wrong",
    "event_cost": "Cost",
    "event_cost_upcoming": "Upcoming Cost",
    "event_not_found": "No event found",
    "event_payment": "Payment",
    "event_trip": "Trip",
    "export": {
      "button": {
        "primary": "Export CSV"
      },
      "description": "With one click you get all your events as a CSV file sent to your email address:",
      "title": "Export your data"
    },
    "filter": {
      "all": "All",
      "archived_cars": "Deleted cars",
      "charging": "Charging",
      "cost": "Costs",
      "payment": "Fueling",
      "show_archived": "Show deleted cars",
      "title_cars": "Show history for selected car",
      "title_events": "Show history for selected events",
      "trip": "Trips",
      "wash": "Wash"
    },
    "merge": {
      "button": {
        "primary": "Merge"
      },
      "title": "Merge trips"
    },
    "minutes_short": "mins",
    "payments": {
      "amazon": "Amazon Pay",
      "apple": "Apple Pay",
      "credit_card": "Credit Card",
      "google": "Google Pay",
      "mbway": "MB WAY",
      "paypal": "PayPal",
      "POSTPAY_BY_PARTNER": "Payment via ryd pay account"
    },
    "show_more": "Show more",
    "stats": {
      "period": {
        "1": "1 month",
        "2": "6 months",
        "3": "1 year"
      },
      "table": {
        "title": {
          "name": "Category",
          "value": "Amount"
        },
        "total": "Total"
      },
      "title": "Transactions overview"
    },
    "title": "Your last transactions",
    "title-webview": "History",
    "trip": {
      "unmerge": {
        "button": {
          "primary": "Unmerge"
        },
        "description": "Proceeding to unmerge your trip will split back this merged trip into the individual trips.",
        "title": "Unmerge the trip"
      }
    },
    "wash": {
      "amount": "Amount",
      "btn_mark": "Mark as used",
      "btn_used": "Code is used",
      "code": "Your code",
      "empty": "No wash tickets purchased yet",
      "error_mark_code": "There was an error, please try again",
      "instructions": "Instructions",
      "steps": {
        "1": "1. Park your car",
        "2": "2. Get your code ready",
        "3": "3. Enter your code at the machine"
      },
      "used": "used on"
    }
  },
  "landing": {
    "back_cta": "Back to the dashboard",
    "guest_cta": "Continue as Guest",
    "headline": "Pay your fuel from your car",
    "list": {
      "1": "No need to enter the shop",
      "2": "No waiting in line",
      "3": "Refuel faster & keep your distance"
    },
    "login_cta": "Go to login",
    "onboarding_cta": "How ryd works",
    "register_cta": "Register",
    "register_login_cta": "Register or Login",
    "skip_cta": "Skip tutorial",
    "slider": {
      "1": {
        "headline": "Go to registration",
        "text": "Register for free and enter your payment details."
      },
      "2": {
        "headline": "Find a partner fuel station",
        "text": "Fuel stations with ryd pay are marked in dark blue on the map. Click on the \"Pay now\" button on site."
      },
      "3": {
        "headline": "Select the fuel pump in the app",
        "text": "Choose your fuel pump in the ryd app."
      },
      "4": {
        "headline": "Fill up as usual",
        "text": "Fill up as always and finish the refueling process by hooking up the nozzle."
      },
      "5": {
        "headline": "Pay for your fuel from your car",
        "text": "Confirm the amount of the tank and go to “Pay now”. Your invoice will be sent by email."
      },
      "6": {
        "headline": "Enjoy your ride!",
        "text": "Enjoy your ride! The station will be informed of your payment in real time."
      }
    }
  },
  "loadings": {
    "data": "Loading Data..",
    "ev_connecting": "Connecting to the charging station...",
    "ev_unlock": "Unlocking the plug...",
    "history_event": "Retrieving event...",
    "history_event_save": "Saving event...",
    "order_load": "Load order...",
    "order_retrieve": "Retrieving the order...",
    "payment_edit": "Updating payment...",
    "payment_finalize": "Finalizing the payment...",
    "payment_loading": "Loading payments...",
    "payment_register": "Registering the payment...",
    "pump_connecting": "Connecting to the pump...",
    "pump_receiving": "Receiving pumps...",
    "pump_unlock": "Unlocking the pump..."
  },
  "loyalty": {
    "active_title": "Your loyalty cards",
    "add_card": "Add your loyalty card number",
    "add_cta": "Add a loyalty card",
    "add_title": "Please choose a loyalty program",
    "agb_text": "With adding your {program} card you accept the <a href=\"{main_agb}\" target=\"_blank\">terms and conditions.</a>",
    "delete": "Remove",
    "edit": {
      "delete_confirm_btn_cancel": "Cancel",
      "delete_confirm_btn_confirm": "Confirm",
      "delete_confirm_text": "This step will permanently delete your loyalty program data!",
      "delete_dialog_title": "Delete loyalty card",
      "header_title": "Bonus program details",
      "label_card_umber": "Loyalty Card Number",
      "label_details": "Bonus program number",
      "label_details_email": "Bonus program email",
      "label_name": "Loyalty program name"
    },
    "error_add": "Card number format not valid",
    "error_field_required": "Field required",
    "header_title": "Loyalty programs",
    "no_active": "You haven't added any loyalty cards yet.",
    "q8smiles": {
      "add": {
        "headline": {
          "description": "With adding your Q8 Smiles bonus program number, you accept the <a href=\"{t_and_c}\" target=\"_blank\">terms and conditions.</a>",
          "title": "Add your Q8 Smiles account"
        }
      },
      "create": {
        "headline": {
          "description": "With creating your Q8 Smiles account, you accept the <a href=\"{t_and_c}\" target=\"_blank\">terms and conditions.</a>",
          "title": "Create your Q8 Smiles account"
        }
      },
      "form": {
        "add": {
          "button": {
            "primary": "Add",
            "secondary": "Don’t have a Q8 Smiles account yet?"
          }
        },
        "create": {
          "button": {
            "primary": "Create"
          }
        },
        "errors": {
          "age": "You have to be 16 or older to sign up.",
          "already_enrolled": "Customer already exist",
          "api_error": "Unfortunately, something went wrong. Please try again later",
          "not_enrolled": "Provided email or card number does not belong to an existing customer"
        },
        "label": {
          "birthdate": "Birth date (optional)",
          "cardnumber": "Q8 Smiles card number",
          "country": "Country",
          "email": "Email for Q8 Smiles account",
          "firstname": "First Name",
          "gender": "Gender (optional)",
          "language": "Language",
          "lastname": "Last Name"
        },
        "selection": {
          "gender": {
            "option1": "Male",
            "option2": "Female",
            "option3": "/",
            "title": "Gender"
          }
        }
      },
      "title": "Q8 Smiles"
    },
    "save_cta": "Add"
  },
  "main_agb": "I agree on <a href=\"{main_agb}\" target=\"_blank\">terms & conditions.</a>",
  "maintenance": {
    "btn": "Update",
    "headline": "We are currently performing scheduled maintenance on the system",
    "text": "Feel free to grab a coffee, we will be available for you again in a moment.",
    "title": "Maintenance work"
  },
  "map": {
    "adressSearch": {
      "empty": "No results",
      "placeholder": "Enter address"
    },
    "filter": {
      "fueltype": "Fuel type",
      "name": "Filter",
      "plug_power": "Plug output kW",
      "plug_type": "Plug type"
    },
    "footer": {
      "menuItems": {
        "AGB": "TnCs",
        "dataprotection": "Data protection",
        "imprint": "Imprint",
        "statutoryInfos": "Additional statutory info"
      }
    },
    "info": {
      "btn": "Continue",
      "copy": "Powered by ryd GmbH",
      "info": [
        "Register and refuel in under 5 minutes",
        "Secure encrypted payment directly from the car",
        "All gasoline prices on one map",
        "No hidden additional costs!"
      ],
      "test": "this is test",
      "title": "Welcome to ryd pay!"
    },
    "listview": {
      "empty": "No stations found that match your filter"
    },
    "map": {
      "zoomerror": "You have to zoom in to find gas stations"
    },
    "stationDetails": {
      "car_wash": "Purchase a car wash",
      "cta_paynow": "Start ryd pay",
      "offline_tag": "Temp. Offline",
      "offline_text": "Ryd pay is temporarily not available on this station. You can still pay inside the shop during the station opening hours"
    }
  },
  "menu": {
    "address": "Address",
    "History": "Your transactions",
    "pay": "ryd pay",
    "payFinish": "Success",
    "payment": "Payment methods",
    "paySummary": "confirmation",
    "profile": "Profile",
    "settings": "Settings",
    "tax": "VAT data",
    "transactionHistory": "Your transactions"
  },
  "notifications": {
    "account_created": "You will receive in a short time an email with the temporary password so you can login in your account. Please keep in mind that if you misspelled your email address you will need to register again as you won't receive your password.",
    "new_pwd_request": "You will receive in short time an email with the temporary password so you can login in your account."
  },
  "onboarding": {
    "action": {
      "continue": "Got it"
    }
  },
  "passwordvalidation": {
    "title": "Please confirm the payment with your password"
  },
  "paymentPin": {
    "btn_close": "Close",
    "btn_forgotten": "Forgot PIN?",
    "create": {
      "text": "For your security, you will be asked for this when making a payment. You can change your PIN at any time by entering your payment details again.",
      "title1": "Choose a PIN for your future payments.",
      "title2": "Confirm PIN"
    },
    "cta": "Click here to enter the PIN",
    "dialog": {
      "btn": "OK",
      "btn_password": "Use password to pay",
      "btn_reset": "Reset PIN",
      "text": "You can change your PIN by adding your payment method again.\nOr you can use your password to authorize the payment.",
      "title": "Forgot PIN?"
    },
    "validate": {
      "error": "PIN does not match. Try again.",
      "error_firebase": "PIN verification was not possible, please try again or select another payment method",
      "error_only_numbers": "Only numbers",
      "title": "Enter PIN"
    }
  },
  "payments": {
    "setup": {
      "mbway": {
        "authorize": {
          "description": "You won’t need to validate your future purchases in MB WAY.",
          "link": "Learn more",
          "title": "I want to add ryd to my MB WAY Authorized Payments"
        },
        "edit": {
          "expires": "Valid until",
          "info_expired": "Your MB WAY Authorized Payments mandate has expired. Please approve a new authorization to continue seamless payments",
          "info_suspended": "Authorized payments are suspended. You can still use MB WAY wallet to pay with us. For faster and more convenient payments, reactivate the authorized payments in your MB WAY application",
          "limit": "Limit without PIN MB WAY"
        },
        "sub_title": "Please add your phone number in order to be able to pay using MB WAY",
        "title": "Add your default phone number"
      }
    }
  },
  "pm_not_supported": {
    "add_payment": "Add a new payment method",
    "poi": "Not supported on this gas station",
    "subtitle": "Please select a different payment method from your list of added ones or add a new one. ",
    "supported_PMs": "Supported payment methods:",
    "title": "Your preferred payment method is not supported on this gas station"
  },
  "profile": {
    "main": {
      "label_address": "Change address",
      "label_cars": "Cars",
      "label_cars_sub": "Manage your cars",
      "label_data": "Profile data",
      "label_email": "email address",
      "label_logout": "Logout",
      "label_loyalty": "Loyalty programs",
      "label_loyalty_sub": "Manage your loyalty cards",
      "label_manage": "Manage profile data",
      "label_no_payment": "No payment methods",
      "label_passwort": "Change password",
      "label_payment": "Payment methods",
      "label_pm_details": "Details",
      "label_pm_name": "Name",
      "label_tab_manage": "Manage profile",
      "label_tab_pay": "ryd pay",
      "label_tax": "My VAT-ID",
      "label_transaction": "Transaction history",
      "label_vouchers": "Vouchers",
      "label_vouchers_sub": "Manage your vouchers",
      "payment-method": {
        "agb_text": "By adding your payment method, you accept the ryd pay <a href=\"{main_agb}\" target=\"_blank\">terms and conditions.</a>",
        "all_payment_added": "Your valid payment method is already successfully set up",
        "amazonpay_success": {
          "button": "OK",
          "text": "Amazon Pay has been added as your default payment method",
          "title": "Added successfully"
        },
        "applepay_success": {
          "button": "OK",
          "text": "Apple Pay has been added as the default payment method. All future payments will be made with Apple Pay.",
          "title": "Added successfully"
        },
        "btn_add": "Add payment method",
        "btn_change": "Edit",
        "btn_delete": "Delete",
        "credit_card": "Credit Card",
        "default_success_message": {
          "button": "OK",
          "text": "The payment method has been added successfully.",
          "title": "Added successfully"
        },
        "default_success": {
          "button": "OK",
          "text": "The payment method has been added successfully.",
          "title": "Added successfully"
        },
        "delete_confirm": {
          "btn_cancel": "Cancel",
          "btn_confirm": "Confirm",
          "btn_proceed": "Delete payment details permanently",
          "text": "This will permanently delete your payment data!\n\nTo be able to use ryd pay, valid payment data must be stored.\n\nIf you only want to change your payment data, then use the corresponding button.",
          "text_attention": "Attention",
          "text_mpm": "This will permanently delete your payment data! To be able to use ryd pay, you need to have at least one valid payment method. You can proceed if others exist.",
          "title": "Delete payment details"
        },
        "empty": "Add a payment method now to use ryd pay in the future.",
        "gpay_success": {
          "button": "OK",
          "text": "Google Pay has been added as the default payment method. All future payments will be made with Google Pay.",
          "title": "Added successfully"
        },
        "header": "Add a new payment method",
        "mbway_success": {
          "button": "OK",
          "text": "MB WAY has been edited",
          "title": "Edited successfully"
        },
        "mbway": {
          "sub_title": "Please add your phone number in order to be able to pay using MB WAY",
          "title": "Add your default phone number"
        },
        "method_cc": "Card payment",
        "method_paypal": "PayPal",
        "no_valid_2SV": "You need to set up your account again",
        "not_supported_pm": "Not supported on this device",
        "preferred": "preferred",
        "preferred_description": "Preferred payment methods can not be deleted.",
        "preferred_success_message": {
          "button": "OK",
          "text": "The payment method has been set as the preferred successfully.",
          "title": "Success"
        },
        "remove": "Remove",
        "set_preferred": "Set as preferred"
      },
      "title": "Your ryd pay",
      "title_manage_data": "Manage your profile data",
      "title_pay": "Payment method",
      "title_payment": "Your payment methods"
    },
    "no_payment": "No payment method added yet",
    "password": {
      "button": "Confirm",
      "label_password": "New password",
      "label_password_confirm": "Repeat new password",
      "success": {
        "button_ok": "OK",
        "text": "We have successfully saved your new password. Please log in with the new password the next time you visit.",
        "title": "Password has been saved"
      },
      "text": "Please choose a secure password: use at least 8 characters, upper and lower case letters as well as numbers and special characters.",
      "title": "Assign a new password"
    },
    "payment_register": {
      "bio": {
        "description": "Your secure PIN to only authorize payments on this device",
        "title": "Biometrical PIN"
      },
      "pin": {
        "description": "Your PIN to approve payments on multiple devices",
        "title": "PIN"
      },
      "subtitle": "Select the security method which will secure your payments",
      "title": "PIN for every payment"
    },
    "referafriend": {
      "subtitle": {
        "disabled": "In order to have an opportunity to invite a friend and receive all the relevant bonuses, you need at least one transaction performed for your account.",
        "enabled": "Invite friends and get vouchers"
      },
      "title": "Refer a friend"
    },
    "wash": {
      "subtitle": "Manage your wash tickets",
      "title": "Wash tickets",
      "title_details": "Wash tickets details"
    }
  },
  "psp": {
    "type": {
      "bankaccount": "Bank account"
    }
  },
  "referafriend": {
    "button": {
      "primary": "Invite a friend"
    },
    "content": {
      "disabled": "In order to have an opportunity to invite a friend and receive all the relevant bonuses, you need at least one transaction performed for your account",
      "empty": "No friends have accepted your invitation code yet."
    },
    "data": {
      "status": {
        "accepted": "Accepted",
        "completed": "Completed",
        "progress": "In Progress"
      }
    },
    "error": "There were a issue retrieving your status",
    "page": {
      "title": "Refer a friend"
    },
    "share": {
      "deeplink": "https://bit.ly/rydpayapp",
      "error_code": "There was an error generating your code. Please try again",
      "text": "Pay for fuel quickly & comfortably via app. {deeplink} With my discount code {code} you instantly receive a 5 cents per liter discount on your first refuel and another 10 cents per liter discount once you fueled 100 liters. Just download the app, copy this message and paste it into the ryd app's voucher wallet.\n",
      "text_copied": "Code copied to clipboard",
      "title": "Share your referral code"
    },
    "stats": {
      "data": {
        "filled": "vouchers",
        "none": "None"
      },
      "left": {
        "title": "Pending"
      },
      "right": {
        "title": "Received"
      }
    },
    "subtitle": {
      "text": "Invite your friends and benefit together. When a friend downloads the app and adds your discount code, he receives a 5 cents per liter discount. As soon as a friend fueled 100 liters with ryd pay, you both receive a 10 cents per liter discount.",
      "tncs": {
        "link": "https://www.ryd.one/de/freunde-werben/",
        "text": "More information and TnC's."
      }
    },
    "title": "Benefit together!"
  },
  "settings": {
    "section_help": {
      "hotline_availability": "Monday to Friday, 9:00 to 18:00h (GMT +2)",
      "label_tel": "Help via phone",
      "onboarding_link": "See the ryd pay onboarding",
      "title": "Help section"
    },
    "section_legal": {
      "title": "Legal"
    }
  },
  "signIn": {
    "login": {
      "cta": "Continue with {provider}",
      "mail_cta": "Login with Email",
      "name": "Login",
      "separator": "Or"
    },
    "providers": {
      "mail": "Email"
    },
    "register": {
      "cta": "Sign up with {provider}",
      "dialog": {
        "content": "Sadly we couldn't find a registered account. Please click on register to create an account.",
        "title": "No account found"
      },
      "email": {
        "headline": "Enter your own password"
      },
      "headline": "Create an account now!",
      "legal_text": "With your registration you accept the <a href=\"{terms_and_conditions}\" target=\"_blank\">terms and conditions</a>. We process your data according to our <a href=\"{data_protection}\" target=\"_blank\">data protection information</a>.",
      "name": "Register",
      "protection_consent": "I agree, subject to revocation at any time, that ryd GmbH may send me information and offers about products and services around the car provided by ryd and/or their partners according to the <a href=\"{declaration_of_consent}\" target=\"_blank\">declaration of consent.</a>",
      "separator": "Or",
      "via_email": "via email",
      "via_push_notifications": "via push notifications"
    },
    "sign_in_amazon": "Sign in with"
  },
  "topup": {
    "confirmation": {
      "wash": {
        "guest": {
          "button": {
            "primary": "Login ",
            "secondary": "or Register "
          },
          "description": " Please register or login with your account to complete your purchase",
          "title": "Almost done"
        },
        "package": "Package"
      }
    },
    "ev": {
      "finish": {
        "text": "You can continue driving. Your invoice will be sent by email.",
        "title": "Charging completed"
      }
    },
    "hint": {
      "error": {
        "button": {
          "primary": "Pay in store"
        },
        "description": "Sorry, you already started fuelling your car before activating the pump via the app. This time you will need to pay inside the store.",
        "page": {
          "title": "ryd pay"
        }
      },
      "page": {
        "title": "ryd pay"
      }
    },
    "mileage": {
      "button": {
        "primary": "Submit odometer reading",
        "secondary": "Skip"
      },
      "description": "Mit Eingabe deines Kilometerstandes willigst du jederzeit widerruflich ein, dass wir, die ryd GmbH, deinen Kilometerstand zusammen mit dem Voucher-Code, mit dem Du Dich by ryd registriert hast, an die Allane SE, Dr.-Carl-von-Linde-Straße 2, D-82049 Pullach, weiterleiten.<br>Die Allane SE nutzt diese Daten zur Beurteilung der Bereitschaft der Kund:innen, das Angebot in Anspruch zu nehmen sowie zu Zwecken einer etwaigen werblichen Ansprache über ggf. sinnvolle Serviceleistungen, abhängig von den gefahrenen Kilometern. Der Datenschutzbeauftragte der Allane SE ist unter dataprotection{'@'}allane.com zu erreichen. Weitere Informationen in den Datenschutzhinweisen der Allane SE unter <a href=\\\"www.sixt-neuwagen.de/datenschutz\\\" target=\\\"_blank\\\">www.sixt-neuwagen.de/datenschutz</a>\".<br>Die Eingabe des Kilometerstands ist optional.",
      "input": {
        "errors": {
          "max": "The maximum value is 1000000"
        },
        "label": {
          "mileage": "odometer reading",
          "vehicle": "car"
        }
      },
      "title": "Mileage"
    },
    "order": {
      "data": {
        "amount": "Tank amount",
        "campaign": "MasterCard XYZ voucher",
        "total": "Grand total"
      }
    },
    "prepay": {
      "hint": {
        "1": {
          "button": {
            "primary": "Continue",
            "secondary": "Ups! Already picked up the nozzle"
          }
        },
        "2": {
          "button": {
            "primary": "Continue",
            "secondary": "I already started fueling"
          }
        },
        "steps": {
          "1": "Select your pump number and the amount in the app",
          "2": "Confirm payment",
          "3": "Is pump ready?\nPick up the nozzle",
          "4": "Already started fueling?You have to pay in the store",
          "5": "Haven’t yet started fueling?Put the nozzle back, and Continue"
        }
      }
    },
    "pumpSelection": {
      "guestCheckoutHint": {
        "button": {
          "primary": "Register or Login",
          "secondary": "Continue as Guest"
        },
        "details": "As a guest you can pay for your fuel via Google Pay. If you want to keep track of your fueling history or gain access to more features and payment methods, please login or register.",
        "title": "Guest checkout"
      }
    },
    "success": {
      "feedback": {
        "button": "Finish",
        "data": {
          "negative": {
            "1": "Dirty",
            "2": "Slow",
            "3": "Hard to use",
            "4": "Wrong price",
            "title": "What did you not like?"
          },
          "positive": {
            "1": "Clean",
            "2": "Fast",
            "3": "Easy to use",
            "title": "What did you enjoy?"
          }
        },
        "title": "How was your fuelling?"
      },
      "title": "Payment successful!",
      "wash": {
        "code": "Activation code",
        "expires": "expires on",
        "hint": "You will find this wash activation code on your invoice, transaction history, and in the new \"Wash tickets\" profile menu"
      }
    },
    "wash": {
      "button": "Purchase car wash",
      "hint": "* All wash details come from the provider and my vary in some cases",
      "no_data": "There is no current data about this wash program",
      "page": {
        "confirm": "Confirm",
        "no_wash": "No wash products",
        "title": {
          "selection": "Select your package"
        }
      }
    }
  },
  "vouchers": {
    "activated": "Active",
    "active": "Activate",
    "add_custom": {
      "add": "Add",
      "error_redeemed": "Voucher already redeemed",
      "error_valid": "Voucher code could not been verified",
      "label_input": "Voucher code",
      "sub_title": "Enter your code and secure your voucher for one of your next fueling.",
      "title": "Please add your voucher code"
    },
    "add_new": "Add voucher code",
    "card": {
      "button": {
        "primary": "Activate",
        "secondary": "Deactivate"
      },
      "voucherCount": "Remaining"
    },
    "daysLeft": "days left",
    "error": {
      "load_vouchers": "There was an error in retrieving your vouchers. Please try again later",
      "sub_title": "Voucher couldn’t be activated.<br>Please try again.",
      "title": "Something went wrong"
    },
    "gift_available": "Available",
    "gift_balance": "balance",
    "gift_credit": "credit",
    "no_vouchers": "You don't have any applicable vouchers yet",
    "of": "of",
    "remaining": "Remaining",
    "title": "Your vouchers",
    "title_header_details": "Voucher",
    "title_header_list": "Vouchers",
    "tnc_accordion": "TnC’s",
    "tnc_link": "TnC’s link"
  },
  "warnings": {
    "email_has_account": "You are trying to register with an email address which was already registered. Please use another email address or request a new password if you forgot the previous one!"
  },
  "wash": {
    "details": {
      "button": {
        "used": "Code is used",
        "valid": "Mark as used"
      },
      "instructions": "Instructions",
      "label": {
        "code": "Activation code",
        "price": "Price"
      },
      "page": {
        "title": "Wash tickets details"
      }
    },
    "empty": "No wash tickets purchased yet",
    "page": {
      "title": "Wash tickets"
    },
    "title": "Your wash tickets"
  },
  "webauthn": {
    "btn_change": "Change payment PIN",
    "btn_cta": "Click here to use your biometric PIN",
    "forgotten": {
      "btn_close": "Close",
      "text": "You can change your biometric PIN at any time by entering your payment details again.",
      "title": "Biometric PIN is not working?"
    },
    "login": {
      "text": "Please confirm your payment with the stored biometric PIN",
      "title": "Enter biometric PIN"
    },
    "register": {
      "text": "From now on, every payment will be approved with your biometric PIN. You can change your biometric PIN at any time by entering your payment details again.",
      "title": "Enter biometric PIN"
    }
  }
}