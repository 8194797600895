<template>
  <div class="flex items-center justify-between px-4 py-6">
    <RPButton 
      theme="secondary" 
      class="justify-between w-auto min-w-[140px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterEvent = true"
    >
      <div class="text-primary-accent">
        {{ eventSelectedFilter === 'ALL'
          ? $t('history.details.show_all')
          : $t(`history.filter.${EVENT_FILTER_TITLE_MAP[eventSelectedFilter]}`) }}
      </div>
      <RPIcon type="arrow-down" />
    </RPButton>
    <RPButton 
      v-if="authState.userPreferences.length > 0"
      theme="secondary" 
      class="justify-between w-auto min-w-[80px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterVehicle = true"
    >
      <RPCarIcon
        :avatar="historyState.thingToShowEvents.avatarImageName"
        :color="historyState.thingToShowEvents.avatarColor"
        class="w-6 mr-2 h-fit"
      />
      <span class="pr-2">{{ historyState.thingToShowEvents.nickName }}</span>
      <RPIcon type="arrow-down" />
    </RPButton>
  </div>
  <ModalBottom v-if="isFilterEvent" class="text-left" @close="isFilterEvent = false">
    <p class="rp-fs-16-ln font-semibold">
      {{ $t('history.filter.title_events') }}
    </p>
    <ul class="pt-6">
      <template v-for="filter in eventFiltersMap" :key="filter.type">
        <li 
          v-if="filter.visible" 
          class="mb-6" 
          :class="{ 'text-tertiary-text font-semibold': isFilterActive(filter.type) }"
          @click="selectFilter(filter.type)"
        >
          <button>{{ $t(`history.filter.${filter.text}`) }}</button>
        </li>
      </template>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="isFilterVehicle" class="text-left" :customClass="['h-[50vh]', 'pb-3']" @close="isFilterVehicle = false">
    <div class="flex justify-between items-center">
      <p class="rp-fs-16-ln font-semibold">
        {{ $t('history.filter.title_cars') }}
      </p>
    </div>

    <ul class="pt-6">
      <li 
        v-for="vehicle in vehicles" 
        :key="vehicle.thingId" 
        class="h-10 flex items-center"
        :class="{ 'text-tertiary-text': historyState.thingToShowEvents.thingId === vehicle.thingId }"
        @click="selectVehicle(vehicle)"
      >
        <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
        <button class="h-fit">
          {{ vehicle.nickName }}
        </button>
      </li>
    </ul>
    <Divider class="mt-4 mb-4" />
    <Checkbox id="showArchivedCheck" v-model="showArchived" :label="$t('history.filter.show_archived')" @update:modelValue="getArchivedCars" />
    <Divider class="mt-4 mb-4" />

    <LoadingSpinner v-if="archivedLoading && showArchived" />

    <template v-else-if="vehiclesArchived.length && showArchived">
      <p class="rp-fs-16-ln font-semibold">
        {{ $t('history.filter.archived_cars') }}
      </p>
      <ul class="pt-6 text-left opacity-50">
        <li 
          v-for="vehicle in vehiclesArchived" 
          :key="vehicle.thingId" 
          class="h-10 flex items-center"
          :class="{ 'text-tertiary-text': historyState.thingToShowEvents.thingId === vehicle.thingId }"
          @click="selectVehicle(vehicle)"
        >
          <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
          <button class="h-fit">
            {{ vehicle.nickName }}
          </button>
        </li>
      </ul>
    </template>
  </ModalBottom>
</template>
<script setup lang="ts">
import ModalBottom from '@/components/ModalBottom.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPCarIcon from '@/components/CarsSVGComponents/RPCarIcon.vue';
import Divider from '@/components/Divider.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Checkbox from '@/components/Checkbox.vue';

import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { useVehicleState } from '@/store/vehicle/vehicle';
import { ThingAvatars } from '@/api/user';
import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const emit = defineEmits(['eventChange', 'carChange']);
const historyState = useHistoryStore();
const authState = useAuthStore();
const vehicleState = useVehicleState();

const route = useRoute();
const router = useRouter();

//Events filter
const isFilterEvent = ref(false);
const isFilterVehicle = ref(false);
const selectFilter = (event: string) => {
  emit('eventChange', event);
  isFilterEvent.value = false;
};
const eventFiltersMap = computed(() => [
  { type: 'ALL', text: 'all', visible: true },
  { type: 'PAYMENT', text: 'payment', visible: true },
  { type: 'TRIP', text: 'trip', visible: true },
  { type: 'COST', text: 'cost', visible: true },
]);
const EVENT_FILTER_TITLE_MAP = {
  ALL: 'all',
  PAYMENT: 'payment',
  TRIP: 'trip',
  COST: 'cost',
};
const eventSelectedFilter = computed(() => historyState.eventSelectedFilter);
const isFilterActive = (filter: string) => filter === eventSelectedFilter.value;

// Vehicles filter
const vehicles = computed(() => authState.userPreferences);

const selectVehicle = (vehicle: ThingAvatars) => {

  if (historyState.thingToShowEvents.thingId !== vehicle.thingId) {

    historyState.thingToShowEvents = vehicle;
    // Going back and forth between pages we need to keep the car the user selected
    // We replace the thingId param in the URL
    router.replace({
      path: route.path,
      query: { ...route.query, thingId: vehicle.thingId }
    });

    emit('carChange', vehicle.thingId);
  }
  isFilterVehicle.value = false;
};
const archivedLoading = ref(false);
const getArchivedCars = async () => {
  if (!vehiclesArchived.value.length && showArchived.value) {
    try {
      archivedLoading.value = true;
      await vehicleState.getArchivedCars();
    } catch (error) {
      console.log(error);
    } finally {
      archivedLoading.value = false;
    }
  }
};

const showArchived = defineModel('showArchived', { type: Boolean, default: false });
const vehiclesArchived = computed(() => vehicleState.archivedCars);
</script>