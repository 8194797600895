<template>
  <div class="px-4 w-full pt-6">
    <div class="w-full flex items-center justify-between">
      <div>
        <p class="font-bold rp-fs-16-ln flex">
          <RydIcon icon="ev-station-icon" />
          {{ event.information?.poi.brand }}
        </p>
        <div class="rp-fs-12-ln">
          {{ getFullAddress }}
        </div>
      </div>
      <a class="navButton" :href="`https://maps.google.de/maps?daddr=${decodeURI(getFullAddress)}`">
        <RPIcon type="navIcon" noFill />
      </a>
    </div>
    <Divider class="w-full my-4" />
    <p class="flex justify-between w-full font-medium">
      <span>{{ $t('history.details.payment.ev.order') }}</span>
      <span class="font-normal text-tertiary-text">{{ event.information.orderId }}</span>
    </p>
    <Divider class="w-full my-4" />
    
    <!-- Station Info -->
    <section class="w-full space-y-2">
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.payment.ev.time') }}</span>
        <span class="font-normal">{{ formatDate.weekday }} {{ formatDate.date }} - {{ formatDate.time }}</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.payment.ev.plug_type') }}</span>
        <span class="font-normal">{{ event.information.items[0].details.standard }}</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.payment.ev.plug_power') }}</span>
        <span class="font-normal">{{ event.information.items[0].details.power }} kWh</span>
      </p>
      <p class="flex justify-between w-full font-medium">
        <span>{{ $t('history.details.unit_price') }}</span><span class="font-normal">{{ pricePerUnit }}</span>
      </p>
    </section>

    <Divider class="w-full my-4" />

    <p class="flex justify-between w-full font-medium">
      <span>{{ $t('history.details.payment.ev.mileage') }}</span>
      <span class="font-normal">{{ formatMileage }}</span>
    </p>

    <Divider class="w-full my-4" />
    
    <!-- Price info -->
    <section class="w-full space-y-2">
      <template v-for="price in event.information.items[0].discriminatedPrices" :key="price._id">
        <div v-if="price.type !== 'FLAT'" class="flex justify-between w-full font-medium">
          <p v-if="price.type === 'ENERGY'">
            {{ $t('history.details.payment.ev.charging') }}
          </p>
          <p v-if="price.type === 'PARKING_TIME'">
            {{ $t('history.details.payment.ev.parking') }}
          </p>
          <p v-if="price.type === 'TIME'">
            {{ $t('history.details.payment.ev.time_tarif') }}
          </p>
          <div class="flex flex-col justify-between items-end">
            <span class="text-tertiary-text font-normal">
              {{ price.details.quantity }} {{ EV_UNITS[price.details.unit as string] }}
            </span>
            <span class="font-normal">{{ currencyPriceDeciCent(price.totalPrice, event.information.price.currency) }}</span>
          </div>
        </div>


        <div v-if="price.type === 'FLAT'" class="flex justify-between w-full font-medium">
          <p>{{ $t('history.details.payment.ev.one_time_fee') }}</p>
          <div class="flex flex-col justify-between items-end">
            <span class="font-normal">{{ currencyPriceDeciCent(price.totalPrice, event.information.price.currency) }}</span>
          </div>
        </div>
      </template>
     
      
      <!-- Vouchers -->
      <template v-if="vouchers.length">
        <p v-for="voucher in vouchers" :key="voucher.id" class="flex justify-between w-full font-medium">
          <span>{{ voucher.details.name }}</span>
          <span class="font-normal text-tertiary-base">
            {{ currencyString((voucher.totalPrice / 1000), voucher.currency) }}
          </span>
        </p>
      </template>
      <!-- Loyalty -->
      <template v-if="event.information.loyaltyItems">
        <div 
          v-for="loyalty in event.information.loyaltyItems" 
          :key="loyalty.id"
          class="flex justify-between w-full font-medium"
        >
          <span>{{ loyalty.programName }}</span>
          <div>
            <span v-if="loyalty.totalAmount" class="font-normal">
              ({{ loyalty.totalAmount }})
            </span>
            <span class="font-normal text-tertiary-base">+{{ loyalty.amount }}</span>
          </div>
        </div>
      </template>
    </section>
    <Divider class="w-full my-4" />
    <!-- Payment method -->
    <div v-if="event.information.pspInformation" class="w-full">
      <p class="flex justify-between w-full font-medium rp-fs-16-ln">
        <span>{{ $t(getPaymentMethod) }}</span>
        <span class="font-normal">{{ event.information.pspInformation.maskedProviderIdentifier }}</span>
      </p>
    </div>

    <Divider class="w-full my-4" />

    <p class="flex justify-between w-full font-medium rp-fs-18-ln">
      <span>{{ $t('history.details.payment.ev.total') }}</span>
      <span>{{ currencyString(normalizeTotalPrice, event.information.price.currency) }}</span>
    </p>
  </div>
</template>
<script setup lang="ts">
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import Divider from '@/components/Divider.vue';

import { HistoryPaymentEventDetail } from '@/store/history/types';
import { useAuthStore } from '@/store/auth/auth';
import { EV_UNITS } from '@/utils/utils';

import { computed, PropType, } from 'vue';
import { currencyString, dateString, currencySymbol, localeNumber } from '@/utils/utils';

const props = defineProps({
  event: {
    type: Object as PropType<HistoryPaymentEventDetail>,
    required: true
  }
});

const authStore = useAuthStore();

const formatDate = computed(() => {
  const startDate = new Date(props.event.sampleTime);
  return dateString(startDate);
});

const PAYMENT_METHODS_MAP = {
  DIRECT_CREDIT: 'history.payments.credit_card',
  GOOGLE_PAY: 'history.payments.google',
  APPLE_PAY: 'history.payments.apple',
  MBWAY: 'history.payments.mbway',
  PAYPAL: 'history.payments.paypal',
  AMAZON_PAY: 'history.payments.amazon',
  POSTPAY_BY_PARTNER: 'history.payments.POSTPAY_BY_PARTNER',
  POSTPAY_BY_USER: 'POSTPAY_BY_USER'
};
const getPaymentMethod = computed(() => PAYMENT_METHODS_MAP[props.event.information.pspInformation.providerMethod]);
/**
 * Since there was no way to distinguish between vouchers and product element in the item array
 * We assume that the item[0] is always the product
 * BE introduced the "type" property but it didn't run the migration
 * 
 * Before implementing the logic to relay on the type, wait for BE to run the migration
 */
const pricePerUnit = computed(() => {
  const price = props.event.information.items[0].details.unitPrice;
  const currency = props.event.information.items[0].currency;
  const unit = props.event.information.items[0].details.unit;

  const currencyPerUnitSymbol = currencySymbol(currency);
  const parseNumber = localeNumber(price / 1000);
  const formattedUnit = EV_UNITS[unit as string];

  return `${parseNumber} ${currencyPerUnitSymbol}/${formattedUnit}`;
});

const currencyPriceDeciCent = (price: number, currency: string) => {

  const parseNumber = Number(price) / 1000;

  return currencyString(parseNumber, currency);
};

const normalizeTotalPrice = computed(() => Number(props.event.information.price.amount) <= 0
  ? 0
  : props.event.information.price.amount);

const formatMileage = new Intl.NumberFormat(authStore.lang || undefined, {
  style: 'unit',
  unit: 'kilometer',
  unitDisplay: 'short',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
}).format(props.event.information.carOdometerM / 1000);

const getFullAddress = `${props.event.information?.poi.address.street} ${props.event.information?.poi.address.houseNumber}, 
  ${props.event.information?.poi.address.zip} ${props.event.information?.poi.address.city}`;

const vouchers = computed(() => {
  return props.event.information?.items.filter(item => item.type === 'CONSUMABLES');
});


</script>
<style lang="scss" scoped>
  p {
    margin-top: 0
  }
</style>