<template>
  <template v-if="componentReady">
    <TopBar 
      :title="$t(componentTitle)"
      backBtnVisible
      iconColor="text-white"
      :rightBtnVisible="event && event.type === 'TRIP'"
      rightBtnIcon="edit"
      @backButtonClick="backButtonRedirect()"
      @rightButtonClick="isEventMerged ? openUnmergeModalFunc() : $router.push(`/history/${event.id}/merge`)"
    />
    <Teleport v-if="event && event.type === 'PAYMENT' && showInvoiceButton" to="#topBarIconRight">
      <RPButton theme="custom" class="p-0 min-w-min" @click="downloadPdfFromApp">
        <RPIcon type="export" svgFill="text" />
      </RPButton>
    </Teleport>
  
    <TripDetails 
      v-if="event && event.type === 'TRIP'" 
      :event="(event as HistoryTripEventDetail)" 
    />
    <PaymentDetails 
      v-else-if="event && event.type === 'PAYMENT' && (event as HistoryPaymentEventDetail).information.productType !== 'EV_CHARGE'" 
      :event="(event as HistoryPaymentEventDetail)" 
    />
    <PaymentDetailsEV 
      v-else-if="event && event.type === 'PAYMENT' && (event as HistoryPaymentEventDetail).information.productType === 'EV_CHARGE'" 
      :event="(event as HistoryPaymentEventDetail)" 
    />
    <CostDetails 
      v-else-if="event && event.type === 'COST' && (event as HistoryCostDetail).information?.category !== 'CAR_WASH'" 
      :event="(event as HistoryCostDetail)"
      :error="APIError"
      @update="updateEvent"
      @delete="deleteEvent"
    />
    <template v-else-if="event && event.type === 'COST' && (event as HistoryCostDetail).information?.category === 'CAR_WASH'">
      <div v-if="(event as HistoryCostDetail).information?.carWash" class="flex flex-col items-center grow">
        <WashDetails
          :event="(event as HistoryCostDetail)"
        />
      </div>
      <CostDetails 
        v-else 
        :event="(event as HistoryCostDetail)"
        :error="APIError"
        @update="updateEvent"
        @delete="deleteEvent"
      />
    </template>
    <div v-else class="px-4">
      {{ $t('history.event_not_found') }}
    </div>
    <!-- Invoice API error -->
    <div v-if="invoiceErrorAPI" class="bg-black w-full py-2 px-4 rounded-md mb-3 mt-8">
      <div class="flex items-center" data-cy="error-message">
        <RPIcon noFill type="error" class="w-5 h-5 mr-2" />
        {{ invoiceErrorAPI }}
      </div>
    </div>

    <ModalBottom v-if="openUnmergeModal" class="text-left" @close="openUnmergeModal = false">
      <p class="rp-fs-16-ln font-semibold text-left">
        {{ t('history.trip.unmerge.title') }}
      </p>
      <p class="text-left my-4 ">
        {{ t('history.trip.unmerge.description') }}
      </p>
      <RPButton class="mb-4" theme="primary" @click="unmergeTrip">
        {{ t('history.trip.unmerge.button.primary') }}
      </RPButton>
    </ModalBottom>
  </template>
  <LoadingOverlay v-else />
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import TripDetails from './components/TripDetails.vue';
import PaymentDetails from './components/PaymentDetails.vue';
import PaymentDetailsEV from './components/PaymentDetailsEV.vue';
import CostDetails from './components/CostDetails.vue';
import WashDetails from './components/WashDetails.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ModalBottom from '@/components/ModalBottom.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';

import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';

import { HistoryPaymentEventDetail, HistoryTripEventDetail, HistoryCostDetail, HistoryCreateCostPayload } from '@/store/history/types';
import { downloadInvoiceAPI } from '@/api/events';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const isEnvProd = import.meta.env.VUE_APP_ENV === 'prod';
const route = useRoute();
const router = useRouter();

const historyState = useHistoryStore();
const authState = useAuthStore();

const componentReady = ref(false);
const openUnmergeModal = ref(false);
const { t } = useI18n();

const showInvoiceButton = ref(false);

onMounted(async () => {
  try {
    await historyState.getEventById(route.params.id as string);
    // we have to check if it's a fleet user to show/hide invoice button
    if (!authState.userFeatures.length) await authState.getUserFeatures(authState.accountId);
    const invoiceFeat = authState.userFeatures.find(
      (feat) => feat.feature === 'invoicing');

    showInvoiceButton.value = !invoiceFeat?.skipInvoiceGeneration;
  } catch (error) {
    console.log(error);
  } finally {
    componentReady.value = true;
  }
});

const event = computed(() => historyState.event);

const isEventMerged = computed(() => event.value && event.value.type === 'TRIP' && (historyState.event as HistoryTripEventDetail).isResultOfMerge);

const componentTitle = computed(() => {
  const titleMap = {
    TRIP: 'history.details.title_trip',
    PAYMENT: 'history.details.title_payments',
    COST: 'history.details.title_cost',
    WASH: 'history.details.title_wash'
  };

  const eventType = (event.value as HistoryCostDetail).information?.category === 'CAR_WASH'
    ? titleMap['WASH']
    : titleMap[event?.value.type as keyof typeof titleMap];
  return event.value ? eventType : '';

});

const backButtonRedirect = () => {
  const urlCallbackObject = new URL('webViewEvent://eventDetailBack');
  return route.query.detailsCallback ? window.location.replace(urlCallbackObject.toString()) : router.push('/history');
};

const openUnmergeModalFunc = () => {
  openUnmergeModal.value = true;
};


const unmergeTrip = async () => {
  await historyState.unmergeEvent(event.value.id);
  openUnmergeModal.value = false;
  router.push('/history');
};

// Update
interface PayloadUpdate {
  form: HistoryCreateCostPayload;
  images: {
    imageBuffer: ArrayBuffer;
    type: string;
  }[];
}
const APIError = ref();
const updateEvent = async (payload: PayloadUpdate) => {
  try {
    componentReady.value = false;
    APIError.value = null;

    await historyState.updateEventCost(payload.form, (route.params.id as string));
    if (payload.images.length) {
      payload.images.map(async (image) => await historyState.uploadImage(image.imageBuffer, image.type));
    }
    router.push('/history');
  } catch (error: any) {
    console.log(error);
    if (error.response?.data?.message?.includes('carOdometerM')) {
      APIError.value = 'mileage';
    } else {
      router.push('/error');
    }
  } finally {
    componentReady.value = true;
  }
};

// Delete
interface PayloadDelete {
  category: string;
  deleteType: string;
}
const deleteEvent = async (payload: PayloadDelete) => {
  componentReady.value = false;
  try {
    await historyState.deleteEventCost(payload.category, (route.params.id as string), payload.deleteType);
    router.push('/history');
  } catch (error) {
    console.log(error);
    router.push('/error');
  } finally {
    componentReady.value = true;
  }
};

// Invoice download
const orderId = computed(() => (historyState.event as HistoryPaymentEventDetail).information?.orderId);

const getDataFromBlob = (myBlob: Blob) => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(myBlob);
  });
};
const invoiceErrorAPI = ref('');

const downloadPdfFromApp = async () => {
  try {

    const response = await downloadInvoiceAPI(orderId.value);

    const fileBlob: Blob = new Blob([response.data], {
      type:
        'application/pdf'
    });

    const dataURL: ArrayBuffer | string = await getDataFromBlob(fileBlob);

    const base64Data = (dataURL as string).substr((dataURL as string).indexOf(',') + 1);
    const fileName = `invoice-${orderId.value}.pdf`;

    if (window.Android && window.Android.downloadPdf) {
      window.Android.downloadPdf(base64Data, fileName);
    }
    if (window.webkit) {
      window.webkit.messageHandlers.downloadInvoice.postMessage(base64Data, fileName);
    }

    return base64Data;

  } catch (error) {
    console.log(error);
    invoiceErrorAPI.value = t('loyalty.q8smiles.form.errors.api_error');
    setTimeout(() => {
      invoiceErrorAPI.value = null;

    }, 5000);
  }
};

</script>