// TODO: make this file a composable
import { EventInfoTripAPI } from '@/api/types';
import { HistoryCreateCostPayload, HistoryTripEvent } from './types';
import { Event } from '@/api/types';

export const eventsMap = {
  TRIP: 'TRIP',
  COST: 'COST',
  PAYMENT: 'PAYMENT'
};

export const TRIP_EVENTS_MAP = {
  CORNERING: 'HARD_CORNER',
  ACCELERATION: 'HARD_ACCELERATION',
  BRAKING: 'HARD_BRAKING'
};

export const COST_EVENTS_TEXT_MAP = {
  EV_CHARGE: 'charging',
  FUEL_TOP_UP: 'refuel',
  CAR_WASH: 'wash',
  PARKING: 'parking',
  CAR_CARE: 'care',
  CAR_PARTS: 'tires',
  REPAIR: 'workshop',
  TAX: 'tax',
  INSURANCE: 'insurance',
  LEASING: 'leasing',
  MISC: 'other',
};
export const COST_EVENT_ICONS_MAP = {
  CAR_CARE: 'care',
  CAR_PARTS: 'tires',
  EV_CHARGE: 'charging',
  FUEL_TOP_UP: 'refuel',
  CAR_WASH: 'wash',
  INSURANCE: 'insurance',
  LEASING: 'leasing',
  MISC: 'other',
  PARKING: 'parking',
  REPAIR: 'workshop',
  TAX: 'tax',
};
export const RECURRENCE_TEXT_MAP = {
  NO: 'never',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly'
};
export const DELETE_TEXT_MAP = {
  'onlyThis': 'only_this',
  'thisAndFollowing': 'this_and_following',
  'all': 'all',
};
export const getCategoryIconUtils = (category: string) => COST_EVENT_ICONS_MAP[category as keyof typeof COST_EVENT_ICONS_MAP];
export const getCategoryTextUtils = (category: string) => COST_EVENTS_TEXT_MAP[category as keyof typeof COST_EVENTS_TEXT_MAP];
export const getStartEnd = (event: EventInfoTripAPI) => {
  const start = { ...event.addresses.start, ...event.locations.start };
  const end = { ...event.addresses.end, ...event.locations.end };

  return { start, end };
};


// Get the color of car icon. API returns a weird number
// this code was taken from Android repo. Don't know don't ask.
const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
};
export const getVehicleColor = (color: number) => {
  const isValidColor = Number.isSafeInteger(color) && color <= 0xffffffff;
  if (!isValidColor) {
    console.error(`The incoming avatar color is invalid (not in Integer value area): ${color}. Fallback to white.`);
    return '#fff';
  } else {
    const red = (color >> 16) & 0xff;
    const green = (color >> 8) & 0xff;
    const blue = color & 0xff;
    return rgbToHex(red, green, blue);
  }
};
/**
 * @desc This is a map between the v-model props in CostDetails.vue and the attribute that the API requires
 * v-model: API attr
 * Not all attrs in v-model has to be sent for creating a new cost event
 * It's depend on the category
 */

export const getCostAttributesMap = (payload: HistoryCreateCostPayload) => {
  const commonsCostAttrs = {
    category: payload.category,
    price: Number(payload.priceInCents) * 10, //payload is in cents, API requires deciCent
    sampleTime: payload.dateTime,
    currency: payload.currency
  };
  const createCostAttributesMap = {
    general: {
      ...commonsCostAttrs,
      recurrencePeriod: payload.recurrencePeriod,
      name: payload.name,
      notes: payload.notes,
    },
    FUEL_TOP_UP: {
      ...commonsCostAttrs,
      fuelTopUpAmount: Number(payload.quantityMilliLiters / 100),
      carOdometerM: Number(payload.mileage * 1000),
      tankIsFull: payload.tankLevel,
    },
    EV_CHARGE: {
      ...commonsCostAttrs,
      fuelTopUpAmount: Number(payload.quantityMilliLiters / 100),
      carOdometerM: Number(payload.mileage * 1000),
      tankIsFull: payload.tankLevel,
    },
    PARKING: {
      ...commonsCostAttrs,
      notes: payload.notes,
    }
  };
  return createCostAttributesMap[payload.category as keyof typeof createCostAttributesMap] ?? createCostAttributesMap['general'];
};

export const parseTripEvents = (items: Event[]) => items.reduce(
  (acc, event): HistoryTripEvent[] => {
    const info = event.information.trip;

    const parsedEvent: HistoryTripEvent = {
      ...event,
      distanceM: info.distanceM,
      drivingScore: info.drivingScore,
      information: getStartEnd(info),
      isResultOfMerge: info.isResultOfMerge
    };

    acc.push(parsedEvent);
    return acc;
  }, []);