<template>
  <div class="fixed top-0 left-0 w-screen h-screen z-50 p-0 filter" />
  <button
    class="
      block bg-black/50 
      border-none border-0 outline-none
      w-screen h-screen
      cursor-default
      fixed top-0 left-0
      z-50 p-0"
    @click.prevent="emits('close')"
  >
    <div
      class="bg-white w-full rounded-t-lg box-border fixed bottom-0 z-10 px-4 overflow-hidden blur-none"
      @click.stop
    >
      <div class="max-w-appMaxWidth mx-auto relative" :class="[...props.customClass]">
        <slot></slot>
      </div>
    </div>
  </button>
</template>
<script setup lang="ts">
const props = defineProps({
  customClass: {
    type: Array,
    default: () => []
  }
});
const emits = defineEmits(['close'])

</script>
<style lang="scss" scoped>
  .filter {
    backdrop-filter: blur(2px);
  }
</style>
