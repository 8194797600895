<template>
  <TopBar
    backBtnVisible
    :title="$t('vouchers.title_header_list')"
    iconColor="text-white"
    @backButtonClick="router.push('/vouchers')"
  />
  <LoadingOverlay v-if="isLoading" />
  <form class="view-block px-4 pt-4 pb-6" @submit.prevent="addVoucher">
    <div>
      <h3 class="rp-fs-20-ln text-left font-bold mb-2 ">
        {{ $t('vouchers.add_custom.title') }}
      </h3>
      <p class="text-fs-12 mb-6">
        {{ $t('vouchers.add_custom.sub_title') }}
      </p>

      <label class="text-tertiary-text rp-fs-12-ln">{{ $t('vouchers.add_custom.label_input') }}</label>
      <input v-model.trim="voucherCode" type="text" class="ryd-old" @input="verifyInput" @paste="onPaste" />
      <p v-if="voucherError" class="text-error">
        {{ $t('vouchers.add_custom.error_valid') }}
      </p>
    </div>
    <RPButton :disabled="!voucherCode.length">
      {{ $t('vouchers.add_custom.add') }}
    </RPButton>
  </form>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import { useRouter, useRoute } from 'vue-router';

import { useVouchersStore } from '@/store/vouchers/vouchers';
import { onMounted, ref, watch } from 'vue';

const route = useRoute();
const router = useRouter();


const isLoading = ref(false);
const voucherError = ref(null);
const voucherCode = ref('');
const voucherStore = useVouchersStore();

onMounted(() => {
  // we can have the code in the URL as a param "code" or "v"
  if (route.query.code) {
    voucherCode.value = cleanCode(route.query.code as string);
  }
  if (route.query.v) {
    voucherCode.value = cleanCode(route.query.v as string);
  }
});

// This watch it's needed when the user is pasting the code from the "suggestion" of the soft keyboard.
// For some reason the paste and input events are not triggered in that case
watch(voucherCode, (newVal) => {
  if (newVal) {
    getCode(newVal);
  }
});
const addVoucher = async () => {

  try {
    isLoading.value = true;
    await voucherStore.addCustomVoucher(voucherCode.value);

    const event = new CustomEvent("webViewVoucherAdd");
    window.dispatchEvent(event);
    if (window.webkit) {
      window.webkit.messageHandlers.webViewVoucherAdd.postMessage('webViewVoucherAdd');
    }
    router.push('/vouchers');
  } catch (e) {
    console.log(e);
    voucherError.value = voucherStore.error;

  } finally {
    isLoading.value = false;
  }
};
// for trigger the submit on soft native keyboard
window.onKeyBoardConfirm = () => addVoucher();

const verifyInput = (event: Event | InputEvent) => {
  voucherError.value = false;
  const text = (event.target as HTMLInputElement).value;
  getCode(text);
};
/**
 * @name onPaste
 * @desc don't ask, 🤷‍♀️ look here
 * @see https://thinxnet.atlassian.net/browse/RP-2550 
 */
const onPaste = (event: ClipboardEvent) => {
  event.preventDefault();
  voucherCode.value = '';

  const pastedText = event.clipboardData.getData('Text');
  getCode(pastedText);

};

const getCode = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const findUrl = text.match(urlRegex);
  if (!findUrl?.length) return voucherCode.value = text.trim();

  const buildURL = new URL(findUrl[0]);
  const params = new URLSearchParams(buildURL.search);

  if (params.has('v')) {
    const code = params.get('v');

    voucherCode.value = cleanCode(code.trim());
  } else {
    voucherCode.value = text.trim();
  }
};
const cleanCode = (code: string) => {
  // remove URL encoded space (%20, \s), 
  // keep only letters and numbers 
  // remove the ^ symbol that, for some reason, is not captured from the alpha end digit
  const cleanSpaces = code.replaceAll(/%20/g, "").replaceAll(/\s+/g, '').trim();
  const keepOnlyLettersNumbers = cleanSpaces.replaceAll('^', "").replaceAll("[^\\p{Alpha}\\p{Digit}]+", "");
  return keepOnlyLettersNumbers.replaceAll(/(?:\\r\\n|\\n\\r|\\n|\\r)/g, "");
};
</script>